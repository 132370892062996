import React from "react";
import { Container } from "react-bootstrap";

const Message = () => {
  return (
    <>
      <Container>
        <h1>Message</h1>
      </Container>
    </>
  );
};

export default Message;
