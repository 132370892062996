import React from "react";
import { Container } from "react-bootstrap";

const Notification = () => {
  return (
    <>
      <Container>
        <div>
          <h1>Notification</h1>
        </div>
      </Container>
    </>
  );
};

export default Notification;
