import React from "react";
import { Container } from "react-bootstrap";

const Calendar = () => {
  return (
    <>
      <Container>
        <h1>Calendar</h1>
      </Container>
    </>
  );
};

export default Calendar;
