import React from "react";
import { Container } from "react-bootstrap";

const Setting = () => {
  return (
    <>
      <Container>
        <h1>Setting</h1>
      </Container>
    </>
  );
};

export default Setting;
