// const BASEURL = "https://vectosense.com:8000";
// const BASEURL = "http://192.168.1.2:8000";

const BASEURL = "https://h3fitclub.com:8000";
const COUNTRYAPIKRY = "oo0avUUz3jG1N5pecaa/7A==DRKzJBYbolsvsc5u";
const COUNTRYURL =
  "https://raw.githubusercontent.com/dr5hn/countries-states-cities-database/master/countries.json";

const GOOGLECLINETID =
  "241643172607-2u579aojmtkvfi3fju6i1mtcme0jmrit.apps.googleusercontent.com";

// user roles
const UserRoles = {
  SERVICE_PROVIDER: "Service",
  NORMAL: "Normal",
  ADMIN: "Admin",
  AGENT: "Agent",
  CLIENT: "Client",
};

export { BASEURL, UserRoles, COUNTRYAPIKRY, COUNTRYURL, GOOGLECLINETID };

